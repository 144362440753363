<template>
  <div id="app" v-if="sitelock.permitted">
    <div v-if="user.isAdmin" id="editing" class="flex-row p-2xs gap-2xs">
      <a href="/admin/monitor/">
        <Button> <i class="pi pi-pen-to-square" /> Admin </Button>
      </a>
      <ToggleButton
        v-if="route.name === 'home'"
        v-model="user.editing"
        on-label="Editing"
        off-label="Edit Page"
      />
    </div>
    <AppHeader />
    <div id="app-content" :class="{ container }">
      <div id="ssr-ready" v-if="ready">ssr ready:</div>
      <div id="ssr-not-ready" v-else>waiting for ssr:</div>
      <!-- <div>
        needs: {{ ssr.needs }} --
        ssr: {{ ssr.ready }} --
        ready: {{ ready }} --
        fallback: {{ fallback }} --
      </div> -->
      <RouterView class="page" />
    </div>
    <AppFooter />
    <Toast position="bottom-right" />
    <div id="page-info">{ "file": "{{ filename }}" }</div>
  </div>
  <SiteLock v-else />
</template>

<script setup lang="ts">
import AppFooter from "@/components/AppFooter.vue";
import AppHeader from "@/components/AppHeader.vue";
import { useCartStore } from "@/stores/cart";
import { useSocketStore } from "@/stores/socket";
import { useSSRStore } from "@/stores/ssr";
import { useUserStore } from "@/stores/user";
import api, { resetModifications } from "@virgodev/bazaar/functions/api";

import { timer } from "@paparazzi/utils/promise";
import { useHead } from "@unhead/vue";
import { useLayoutStore } from "@virgodev/web-builder/stores/layouts";
import Button from "primevue/button";
import Toast from "primevue/toast";
import ToggleButton from "primevue/togglebutton";
import { computed, onMounted, onUnmounted, ref, watch, watchEffect } from "vue";
import { RouterView, useRoute, useRouter } from "vue-router";
import SiteLock from "./components/SiteLock.vue";
import { useFirebaseStore } from "./stores/firebase";
import { useShopStore } from "./stores/shop";
import { loadBeacon } from "./utils/loadBeacon";
import { useSiteLockStore } from "./utils/site_lock";

const user = useUserStore();
const router = useRouter();
const route = useRoute();
const ssr = useSSRStore();
const socket = useSocketStore();
const cart = useCartStore();
const sitelock = useSiteLockStore();
const shop = useShopStore();
const firebase = useFirebaseStore();

const layouts = useLayoutStore();
// TODO we need to remove the block that requires this bypass
layouts.adapter.filters.bypass = "p9we5nmwdsoc0mmvsxng";

const ready = ref(false);
const fallback = ref(false);
const lastCheck = ref(Date.now());
let readyTimeout = -1;
user.editing = true;

const container = computed(() => {
  return !route.meta?.fullWidth;
});

const filename = computed(() => {
  if (route.matched && route.matched.length > 0) {
    const myroute = route.matched[0];
    if (myroute.components?.default.__file) {
      return myroute.components.default.__file.split("/src/")[1];
    }
  }
  return "";
});

watch(
  () => ssr.needs,
  () => {
    if (ssr.ready) {
      clearTimeout(readyTimeout);
      readyTimeout = window.setTimeout(() => {
        ready.value = true;
        fallback.value = false;
      }, 1000);
    }
  },
  { deep: true },
);

watchEffect(() => {
  if (user.isRep && /paparazzi/.test(location.host)) {
    loadBeacon();
  }
});

useHead({
  titleTemplate: "%s | Paparazzi Accessories",
});

onMounted(async () => {
  clearTimeout(readyTimeout);
  readyTimeout = window.setTimeout(() => {
    ready.value = true;
    if (!ssr.ready) {
      fallback.value = true;
    }
  }, 15000);

  socket.setup();
  firebase.setup();
  shop.setup();
  cart.setup();

  if (!user.props.id) {
    await user.pull();
  }
  if (!user.isTester) {
    // location = 'https://paparazziaccessories.com'
  }

  window.addEventListener("visibilitychange", checkVersion);

  // check if already logged in with cookies
  if (!user.props.id) {
    // are we already logged in?
    const response = await api({
      url: "profile/my/",
    });
    if (response.ok && response.body.id) {
      const loggedIn = await user.checkLogin();
      if (loggedIn) {
        if (router.currentRoute.value.name === "account-signin") {
          await router.push({ name: "account-profile" });
        }
      }
    }
  }
});

onUnmounted(() => {
  resetModifications();
  window.removeEventListener("visibilitychange", checkVersion);
});

async function checkVersion() {
  if (
    !document.hidden &&
    window.versionHash &&
    lastCheck.value < Date.now() - 1000 * 60 * 15
  ) {
    const response = await api({ url: "site/version/" });
    if (response.ok) {
      console.log(" - versions", response.body.version, window.versionHash);
      if (response.body.version !== window.versionHash) {
        console.warn(" - version stale: reloading");
        await timer(1000);
        location.reload();
      }
    }
  }
}
</script>

<style scoped>
#ssr-ready,
#ssr-not-ready {
  position: absolute;
  bottom: 0;
  right: 0;
  color: transparent;
}
#editing {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000;
}

.page {
  margin: var(--1XL, 64px) auto;
  margin-top: calc(var(--1XL) / 2);
}

#page-info {
  display: none;
}

@media (max-width: 800px) {
  #app-content.container {
    padding: 0 var(--Sm, 24px);
  }
  .page {
    margin: var(--Mobile-main-gap, 40px) auto;
  }
  .content-page {
    display: block;
  }
}
</style>
