import { defineStore } from "pinia";
import { computed, ref, watch, type Ref } from "vue";

import { Deferred } from "@/utils/bouncer";
import { validatePassword } from "@/utils/password_validation";
import { clearUserData } from "@/utils/utilities";
import {
  type Rep,
  type User,
} from "@paparazzi/types/paparazzi.api.serializers.profiles/index";
import { timer } from "@paparazzi/utils/promise";
import api, {
  addRequestHandler,
  addResponseHandler,
} from "@virgodev/bazaar/functions/api";
import copy from "@virgodev/bazaar/functions/copy";
import { useLocalStorageStore } from "@virgodev/bazaar/functions/localstorage/store";
import { createStore } from "@virgodev/vue-models/utils/create_store";
import emailValidator from "email-validator";
import Cookies from "js-cookie";
import LogRocket from "logrocket";
import { useRouter } from "vue-router";

interface TokenUser extends User {
  token: string | null;
}

export interface UserWithId extends User {
  id: number;
}

export interface RepWithId extends Rep {
  id: number;
}

const defaultUser: TokenUser = {
  id: undefined,
  first_name: "",
  last_name: "",
  username: "",
  images: [],
  flags: [],
  groups: [],
  token: null,
  password1: "",
  password2: "",
};

export const useRepsStore = createStore<RepWithId>("reps", {
  endpoint: "profile/reps",
  responseKey: "reps",
  sortKey: "-order_count",
  version: "2",
});

export const useUserStore = defineStore("user", () => {
  const props = ref(copy(defaultUser));
  const errors: Ref<{ [key: string]: string[] }> = ref({});
  const storage = useLocalStorageStore();
  const stored = storage.get("user", defaultUser);
  if (stored && typeof stored === "object") {
    props.value = stored;
  }
  const isLocalhost = location.hostname === "localhost";
  const updateQueue = ref<Record<string, string>[]>([]);
  const isUpdateRunning = new Deferred();
  isUpdateRunning.resolve(); // start resolved
  const isLogrocketing = ref(false);

  if (typeof location === "undefined") {
    props.value.id = "ssr";
    props.value.username = "";
    props.value.token = "";
    props.value.groups = ["tester"];
  }

  const isEditing = ref(false);
  const csrf = ref<string>(window.csrfToken);

  if (!csrf.value) {
    csrf.value = Cookies.get("csrftoken") || "";
  }

  const isAuthenticated = computed(() => !!props.value.id);
  const isRep = computed(() => {
    return props.value.groups.indexOf("wholesale") > -1;
  });
  const isTester = computed(() => {
    return (
      isLocalhost ||
      props.value.groups.indexOf("tester") > -1 ||
      props.value.groups.indexOf("betatester") > -1
    );
  });
  const isTemp = computed(() => {
    return /@/gi.test(props.value.username);
  });
  const isAdmin = computed(() => {
    return props.value.groups.indexOf("admin") > -1;
  });
  const isFashionFix = computed(() => {
    return props.value.groups.indexOf("fashionfix") > -1;
  });
  const editing = computed({
    get: () => {
      return isEditing.value && isAdmin.value;
    },
    set: (v) => {
      if (v && isAdmin) {
        isEditing.value = true;
      } else {
        isEditing.value = false;
      }
    },
  });
  const hasErrors: Ref<Boolean> = computed(() => {
    return errors.value && Object.values(errors.value).length > 0;
  });

  watch([() => props.value.flags], () => {
    startLogrocket();
  });
  if (props.value.flags) {
    startLogrocket();
  }

  addRequestHandler((req: any) => {
    if (!/login/.test(req.url) && props.value.token) {
      req.headers.Authorization = `Token ${props.value.token}`;
    }
    if (csrf.value) {
      req.headers["X-CSRFToken"] = csrf.value;
    }
  });

  addResponseHandler(401, async (req: any) => {
    const router = useRouter();
    if (!/logout/.test(req.url) && props.value.username) {
      await logout();
      router.push("/");
    }
  });

  async function pull(): Promise<boolean> {
    if (props.value.id) {
      console.log("updating profile", props.value.id);
      const response = await api({
        url: "profile/my/",
        params: {
          hash: window.versionHash,
          location: document.location.hash,
        },
      });
      if (response.ok) {
        for (const key in response.body) {
          if (key === "user") {
            for (const key2 in response.body.user) {
              props.value[key2] = response.body.user[key2];
            }
          } else {
            props.value[key] = response.body[key];
          }
        }
      }
      return response.ok;
    }
    return false;
  }

  async function update(data: { [key: string]: any }) {
    if (data.id && props.value.id && props.value.id != data.id) {
      console.error("User id's don't match", data.id, props.value.id);
    }

    for (const prop in data) {
      props.value[prop] = data[prop];
    }
    await save();
  }

  async function save() {
    console.warn("Saving user");
    storage.put("user", props.value);
  }

  async function updateAccount(extra: Record<string, string> = {}) {
    errors.value = {};

    if (extra.password1) {
      if (extra.password1 !== extra.password2) {
        errors.value = {
          ...errors.value,
          ...{ password2: ["Passwords do not match"] },
        };
      }
      const error = validatePassword(extra.password1);
      if (error) {
        errors.value = {
          ...errors.value,
          ...{ password1: [error] },
        };
      }
    }

    if (!props.value.email) {
      errors.value = {
        ...errors.value,
        ...{ email: ["Email is required for registration"] },
      };
    } else if (!emailValidator.validate(props.value.email)) {
      errors.value = {
        ...errors.value,
        ...{ email: ["Invalid email address"] },
      };
    }

    if (
      Object.keys(errors.value).length === 0 &&
      (props.value.id || Object.keys(extra).length > 0)
    ) {
      updateQueue.value.push(extra);

      if (isUpdateRunning.resolved) {
        isUpdateRunning.reset();

        while (updateQueue.value.length > 0) {
          const arg = updateQueue.value.shift();
          if (arg) {
            await updateAccountCall(arg);
          }
        }

        isUpdateRunning.resolve();
      } else {
        await isUpdateRunning.promise;
      }
    } else {
      console.warn("not updating user on server, need an id or extra");
    }
  }

  async function updateAccountCall(extra: Record<string, string> = {}) {
    // attempt to update or create account
    const packet: { [key: string]: any } = {};
    for (const key in extra) {
      if (extra[key]) {
        packet[key] = extra[key];
      }
    }
    for (const key in props.value) {
      if (props.value[key]) {
        packet[key] = props.value[key];
      }
    }

    errors.value = {};
    const response = await api({
      url: "profile/my/",
      json: packet,
      method: isAuthenticated.value ? "PATCH" : "POST",
    });
    if (response.ok) {
      if (response.body.user_id) {
        props.value.id = response.body.user_id;
      }
      if (response.body.token) {
        props.value.token = response.body.token;
      }
    } else if (response.body.errors) {
      errors.value = response.body.errors;
    }
  }

  async function checkLogin(): Promise<boolean> {
    const response = await api({
      url: "users/login/",
      json: {},
      method: "POST",
    });
    if (response.ok && response.body.authenticated) {
      update(response.body);
      return true;
    }
    return false;
  }

  /**
    Logs the client into the rest backend.

    events:
      login is sent when the backend returns a successful response
      the user might not be logged in even if this event fires
      check `user.authenticated`
  */
  async function login(
    username: string,
    password: string,
  ): Promise<string | null> {
    const response = await api({
      url: "users/login/",
      json: { username, password },
      method: "POST",
    });

    if (response.ok && !response.body.error) {
      await update(response.body);
      csrf.value = Cookies.get("csrftoken") || "";
      console.warn("csrf updated", csrf.value);
      return null;
    } else {
      return response.body.error;
    }
  }

  async function logout(opts: { reload: boolean } = { reload: true }) {
    const response = await api({ url: "profile/logout/", method: "POST" });
    if (response.ok) {
      console.log("server logged out");
    }

    await clearUserData();

    isEditing.value = false;
    if (opts.reload) {
      location.reload();
    }
  }

  function reset() {
    props.value = copy(defaultUser);
  }

  async function changeRep(repNumber: string) {
    if (!props.value.rep_number) {
      try {
        const response = await api({
          url: "profile/join/",
          json: { rep_number: repNumber },
          method: "POST",
        });
        if (response.ok) {
          props.value.customer_rep = repNumber;
        }

        return response.ok;
      } catch (ex) {
        console.error("change rep error", ex);
      }
    }
  }

  async function removeRep(repNumber: string) {
    if (!props.value.rep_number) {
      const response = await api({
        url: "profile/leave/",
        json: { rep_number: repNumber },
        method: "POST",
      });
      if (response.ok) {
        if (`${props.value.customer_rep}` === `${repNumber}`) {
          props.value.customer_rep = "";
        }

        return response.ok;
      }
    }
  }

  async function startLogrocket() {
    if (
      props.value.flags &&
      props.value.flags.indexOf("logrocket") > -1 &&
      !isLogrocketing.value &&
      !isLocalhost
    ) {
      console.log("Starting logrocket 🚀", props.value.rep_number);
      isLogrocketing.value = true;
      LogRocket.init("flsra8/paparazzi", {
        release: `c-${window.versionHash}`,
      });
      LogRocket.identify(
        isRep ? props.value.rep_number : `c|${props.value.username}`,
        {
          name: `${props.value.first_name} ${props.value.last_name}`,
        },
      );
      if (!localStorage.debug) {
        await timer(3000);
        console.log("-> showing all logs");
        localStorage.debug = "*";
        await timer(100);
        location.reload();
      }
    }
  }

  return {
    editing,
    props,
    errors,
    hasErrors,
    csrf,
    update,
    save,
    pull,
    login,
    logout,
    reset,
    checkLogin,
    updateAccount,
    changeRep,
    removeRep,
    isAuthenticated,
    isRep,
    isTemp,
    isTester,
    isAdmin,
    isFashionFix,
  };
});
